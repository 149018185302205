<template>
    <v-row>
        <v-col :cols="filterColumnCount" v-if="filtersVisible">
            <base-card>
                <!-- <v-card-title>
                    <span class="font-weight-bold">Filters</span>
                </v-card-title> -->
                <!-- <v-card-text class="-pt-0">
                    <advanced-grid-filters-list :dynamic="false" :fields="filterfields" v-model:filtervalue="filtercriteria" @search="onSearch" />
                </v-card-text>                           -->
            </base-card>            
        </v-col>
        <v-col :cols="gridColumnCount">
            <base-card>
                <!-- <v-card-title class="pb-0">
                    Users
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    
                </v-card-title> -->
                <v-card-title>                                                        
                    <div class="d-flex justify-space-between flex">
                        <h5 class="ma-5 mb-0 d-flex flex">Campaigns</h5>     
                        <v-autocomplete
                            class="ma-4 mb-0 d-flex flex"
                            v-model="selectedStatus"
                            :items="statusOptions"
                            label="Status"      
                            outlined       
                            dense               
                            clearable                                      
                            @change="triggerQuicksearch"
                        ></v-autocomplete>
                        <v-autocomplete
                            class="ma-4 mb-0 d-flex flex"
                            v-model="selectedBrand"
                            :items="brandOptions"
                            label="Brand"    
                            clearable  
                            outlined       
                            dense     
                            @change="triggerQuicksearch"
                        ></v-autocomplete>                   
                        <v-text-field
                                v-model="quicksearch"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details     
                                class="col-5"   
                                @input="triggerQuicksearch"
                            ></v-text-field>
                        <!-- <v-btn class="ma-2" dark color="primary" @click="toggleFilterView">
                                <v-icon>mdi-filter</v-icon>
                                Filters
                        </v-btn> -->
                        <!-- <v-btn class="ma-2" dark>
                            <v-icon>mdi-keyboard_arrow_down</v-icon>
                            Actions
                        </v-btn> -->
                        <!-- <v-btn class="ma-2" dark color="danger" to="/mng/adpoints/devicetypes/create">
                            <v-icon>mdi-plus</v-icon>                            
                        </v-btn>                         -->
                    </div>
                </v-card-title>
                <v-data-table
                    v-model="selected"                    
                    :headers="headers"
                    :options.sync="pagingOptions"
                    :server-items-length="totalItemCount"
                    :items="gridData"
                    item-key="name"
                    :show-select="false"
                    class="elevation-1 table-one"
                    multi-sort
                >
                    <template v-slot:item.title="{item}">
                        <div class="d-flex align-center">
                            <!-- <v-avatar class="mr-2" size="26" dark>
                                <img src="/img/database.c82dbe43.svg" alt="" />
                            </v-avatar> -->
                            <p class="ma-0 font-weight-medium">
                                {{ item.title }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.aud_create_date="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.aud_create_date | formatDate }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.startdatetime="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.startdatetime.replace('T',' ').replace('.000Z','')}} (UTC)
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.enddatetime="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.enddatetime.replace('T',' ').replace('.000Z','')}} (UTC)
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.brand="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ getOptionDescription(brandOptions, item.brand) }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.status="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                <v-chip      
                                        v-if="item.status==='draft'"                                  
                                        class="mr-4 rounded-pill"   
                                        color="blue"                                          
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc(item)}}
                                    </v-chip>
                                    <v-chip     
                                        v-if="statusCalc(item)==='running' || statusCalc(item)==='queued'"                                                                     
                                        class="mr-4 rounded-pill"    
                                        color="primary"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc(item)}}
                                    </v-chip>
                                    <v-chip             
                                        v-if="statusCalc(item)==='completed'"                                                                                                
                                        class="mr-4 rounded-pill"    
                                        color="success"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc(item)}}
                                    </v-chip>
                                    <v-chip             
                                        v-if="statusCalc(item)==='stopped'"                                                                                                
                                        class="mr-4 rounded-pill"    
                                        color="orange"                            
                                        label
                                        small
                                        text-color="white"
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc(item)}}
                                    </v-chip>
                                    <v-chip      
                                        v-if="item.status==='archived'"                                  
                                        class="mr-4 rounded-pill"                                            
                                        label
                                        small                                        
                                    >
                                        <!-- <v-icon small left>mdi-check</v-icon> -->
                                        {{statusCalc(item)}}
                                    </v-chip>
                            </p>
                        </div>
                    </template>                    

                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        :to="'/mng/campaigns/edit/' + item.id"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="black"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="duplicateCampaign(item.id)"
                                    >
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </template>
                                <span>Duplicate</span>
                            </v-tooltip>
                            <v-tooltip top v-if="item.status==='draft'">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="deleteShowConfirmDialog(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>

                            

                        </div>
                    </template>                    
                </v-data-table>
                <v-dialog v-model="deleteDialog" width="500">
                    <v-card class="">
                        <v-card-title>
                            <span class="headline">Delete</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                Are you sure you want to delete this record?
                            </v-container>
                            <!-- <small>*indicates required field</small> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="deleteDialog = false"
                                >Cancel</v-btn
                            >
                            <v-btn
                                color="primary darken-1"
                                text
                                @click="deleteItem()"
                                >Delete</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </base-card>
        </v-col>
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </v-row>
</template>

<script>
import api from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersList from "@/components/core/AdvancedGridFiltersList"
import { statusCalc, getOptionDescription } from "@/core/ui-utils.js"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Device Types List'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersList        
    },
    data() {
        return {
            // gridConfig: {
            //     rowActions: [
            //         {
            //             title: "Edit",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //         {
            //             title: "Delete",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //     ],
            //     columns: [
            //         {title: "Name", 
            //         type: "text", 
            //         bindings: {
            //             datafield: "fullname",
            //             prependIcon: "",
            //             appendIcon: "",
            //             formatter: (rowData) => {
            //                 return v;
            //             },
            //             statusMapper: (rowData) => {
            //                 return {
            //                     color: "",
            //                     icon: "",
            //                     text: ""
            //                 }
            //             },

            //         }}
            //     ]
            // },
            totalItemCount: 0,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            deleteDialog: null,
            itemToDelete: null,
            quicksearch: '',
            pagingOptions: {},
            selected: [],
            baseData: [],
            select_field: null,
            select_operator: null,
            filtercriteria: null,
            filterfields: [],                    
            filtersVisible: false,
            headers: [                
                {text: 'Campaign', align: 'start', value: 'campaignid'},
                {text: 'Brand', align: 'start', value: 'brand'},
                {text: 'Priority', align: 'start', value: 'priority'},
                {text: 'Status', align: 'start', value: 'status'},
                {text: 'Start', value: 'startdatetime'},
                {text: 'End', value: 'enddatetime'},                
                {text: 'Create Date', value: 'aud_create_date', },                
                {text: 'Action', value: 'action'}
            ],


            brandOptions: [],
            selectedBrand: null,

            statusOptions: [
                {value: 'all', text: 'all'},
                {value: 'draft', text: 'draft'},
                {value: 'released', text: 'running (or queued)'},
                {value: 'completed', text: 'completed'},
                {value: 'stopped', text: 'stopped'},
                {value: 'archived', text: 'archived'},
            ],
            selectedStatus: 'all',
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).format("DD/MM/YYYY");
        }
    },
    watch: {
      pagingOptions: {
        handler () {
          this.fetchGridData();
        },
        deep: true,
      },
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        gridData() {
            //const rspData = this.baseData;
            //return this.formatListData(rspData);
            return this.baseData;
        },
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
    },
    methods: {
        formatListData(rspData) {
            const d = rspData.map(d => { 
                return {
                    ...d, 
                    fullname: d.firstName + ' ' + d.lastName,
                    // status: ( (d.disabled) ? "Disabled" :
                    //     (d.invitation_code) ? "Invited" :
                    //     (d.activation_date === "" || d.activation_date == null) ? "Awaiting Activation" : "Active"                         
                    // )
                } })
            return d;
        },
        async duplicateCampaign(cmpnId) {
            const rsp = await api.campaign.duplicate(cmpnId);
            let rspData = rsp.data; //.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
            this.quicksearch = '';
            this.selectedBrand = null;
            this.selectedStatus = null;
            this.triggerQuicksearch();
        },
        statusCalc(campaign) {
            return statusCalc(campaign);
        },
        getOptionDescription,
        // statusCalc(campaign) {                        
        //     if(campaign.status === 'released') {
        //         let startdatetime = new Date(campaign.startdatetime);   
        //         let enddatetime = new Date(campaign.enddatetime);             
        //         if(enddatetime <= Date.now() ) {
        //             return 'completed';
        //         }
        //         else if(startdatetime <= Date.now() ) {
        //             return 'running';
        //         }
        //         else {
        //             return 'queued';
        //         }
        //     }            
        //     else if (campaign.status === 'completed') {
        //         let enddatetime = new Date(campaign.enddatetime);                
        //         if(enddatetime <= Date.now()) {
        //             return 'completed';
        //         }
        //         else {
        //             return 'stopped';
        //         }
        //     }
        //     return campaign.status;
        // },
        async fetchGridData() {            
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.filtercriteria && this.filtercriteria.qs) {                
                qstr += this.filtercriteria.qs;
            }      
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 2){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            } 
            if(this.selectedStatus){
                qstr +="&status=" + encodeURIComponent(this.selectedStatus);
            }
            if(this.selectedBrand){
                qstr +="&brand=" + encodeURIComponent(this.selectedBrand);
            }
            //alert(querystr);
            const rsp = await api.campaign.getList(qstr);
            this.baseData = rsp.data.data; //.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
            this.totalItemCount = rsp.data.total;
        },
        toggleFilterView() {
            this.filtersVisible = ! this.filtersVisible
        },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }             
            this.fetchGridData();
        },
        deleteItem() {            
            this.deleteDialog = false;
            this.error = '';                                                           
            this.loading = true;          
            api.campaign.delete(this.itemToDelete.id)
            .then((rsp)=>{                              
                this.snackbarColor = "green";
                this.snackbarMessage = "Record deleted.";
                this.snackbar = true;  
                this.fetchGridData();                
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })  
        },
        deleteShowConfirmDialog(item) {
            this.itemToDelete = item;
            this.deleteDialog = true;            
        }
    },
    async created() {
        const rsp = await api.adpoint.getInitData();
        this.brandOptions = rsp.data.brandOptions;
        //this.fetchGridData();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {
        // let ff = this.filterfields.find(i=>i.selectvalue === "brand");        
        // if(ff) {
        //     const rsp = await api.brand.getAll();            
        //     ff.listItems = rsp.data.map(i=> {return {selectvalue: i.code, selecttext: i.title}});
        // }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
