var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.filtersVisible)?_c('v-col',{attrs:{"cols":_vm.filterColumnCount}},[_c('base-card')],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.gridColumnCount}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex"},[_c('h5',{staticClass:"ma-5 mb-0 d-flex flex"},[_vm._v("Campaigns")]),_c('v-autocomplete',{staticClass:"ma-4 mb-0 d-flex flex",attrs:{"items":_vm.statusOptions,"label":"Status","outlined":"","dense":"","clearable":""},on:{"change":_vm.triggerQuicksearch},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('v-autocomplete',{staticClass:"ma-4 mb-0 d-flex flex",attrs:{"items":_vm.brandOptions,"label":"Brand","clearable":"","outlined":"","dense":""},on:{"change":_vm.triggerQuicksearch},model:{value:(_vm.selectedBrand),callback:function ($$v) {_vm.selectedBrand=$$v},expression:"selectedBrand"}}),_c('v-text-field',{staticClass:"col-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"input":_vm.triggerQuicksearch},model:{value:(_vm.quicksearch),callback:function ($$v) {_vm.quicksearch=$$v},expression:"quicksearch"}})],1)]),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"headers":_vm.headers,"options":_vm.pagingOptions,"server-items-length":_vm.totalItemCount,"items":_vm.gridData,"item-key":"name","show-select":false,"multi-sort":""},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}},{key:"item.aud_create_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.aud_create_date))+" ")])])]}},{key:"item.startdatetime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.startdatetime.replace('T',' ').replace('.000Z',''))+" (UTC) ")])])]}},{key:"item.enddatetime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.enddatetime.replace('T',' ').replace('.000Z',''))+" (UTC) ")])])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getOptionDescription(_vm.brandOptions, item.brand))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[(item.status==='draft')?_c('v-chip',{staticClass:"mr-4 rounded-pill",attrs:{"color":"blue","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusCalc(item))+" ")]):_vm._e(),(_vm.statusCalc(item)==='running' || _vm.statusCalc(item)==='queued')?_c('v-chip',{staticClass:"mr-4 rounded-pill",attrs:{"color":"primary","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusCalc(item))+" ")]):_vm._e(),(_vm.statusCalc(item)==='completed')?_c('v-chip',{staticClass:"mr-4 rounded-pill",attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusCalc(item))+" ")]):_vm._e(),(_vm.statusCalc(item)==='stopped')?_c('v-chip',{staticClass:"mr-4 rounded-pill",attrs:{"color":"orange","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusCalc(item))+" ")]):_vm._e(),(item.status==='archived')?_c('v-chip',{staticClass:"mr-4 rounded-pill",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.statusCalc(item))+" ")]):_vm._e()],1)])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":"","to":'/mng/campaigns/edit/' + item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","dark":"","icon":""},on:{"click":function($event){return _vm.duplicateCampaign(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicate")])]),(item.status==='draft')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.deleteShowConfirmDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Delete")])]),_c('v-card-text',[_c('v-container',[_vm._v(" Are you sure you want to delete this record? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Delete")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","tile":"","color":_vm.snackbarColor + ' -accent-2'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }